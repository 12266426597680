#profilePanelContainer { position: absolute; right: 0; top: 0; height: 100%; display: flex; }
#profilePanelTogglerContainer { display: flex; }
#profilePanelToggler { padding: 10px; background: none; border: none; color: #b4c2ce; cursor: pointer; }
#profilePanelToggler:active { margin-top: 2px; margin-left: 2px; }
#profilePanelToggler .fa-user { }
#profilePanelToggler .username { padding: 0 10px; font-weight: 600; }
#profilePanelToggler .fa-caret-down { }
#profilePanel { display: none; position: absolute; top: 100%; right: 0; width: 200px; padding: 10px; border: 1px solid #2f4050; background: #222e3a; border-radius: 4px; }
#profilePanel.show { display: block; }
#profilePanel .btnBlock { padding: 15px; box-sizing: border-box; }
#profilePanel .btnBlock button { width: 100%; padding: 8px; }

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px) {
  #profilePanelContainer { }
}
