#root { display: flex; flex-direction: column; width: 100%; }
#content { display: flex; flex-direction: column; align-content: center; flex-wrap: wrap; padding: 50px 10px 10px; }
#content .sidebar { display: flex; }
#content .mainContent { display: flex; flex-grow: 1; }

.gaugeContainer { position: relative; width: 170px; height: 150px; }
.gaugeValContainer { position: absolute; display: flex; flex-direction: row; align-content: center; flex-wrap: wrap; bottom: 10px; left: 25px; right: 25px; text-align: center; text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; }
.gaugeVal { flex-grow: 1; font-size: 15px; line-height: 20px; }
.gaugeVal.activeDealsRisk { color: #3f97e9; }
.gaugeVal.risk { color: #00a59a; }
.gaugeVal.configRisk { color: #ee7d8b; }
.gaugeLabel { display: inline; }
.currencyRiskContainerList { background: #182129; border-radius: 4px; padding: 10px; max-width: 100%; box-sizing: border-box; }
.currencyRiskRowContainer { padding: 10px 0; width: 650px; }
.currencyRiskContainer { display: flex; flex-wrap: wrap; border-bottom: 1px solid #2f4050; padding: 0 10px; }
.applyRiskBtn { align-self: flex-end; }
.riskDataContainer { display: flex; flex-direction: column; flex: 1; min-width: 0; }
.riskDataContainer .bottomRow { display: flex; margin-bottom: 10px; justify-content: space-between; }
.riskDataContainer .bottomRow button.hidden { visibility: hidden; }
.riskDataContainer .riskModeContainer { align-self: flex-end; color: #728faa; }
.riskDataContainer .riskModeContainer label { margin-right: 10px; font-size: 13px; }
.riskDataContainer .riskModeContainer label input { margin-right: 5px; }
.riskDataContainer .riskModeContainer label:last-child { margin-right: 0; }

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px) {
  .currencyRiskContainerList { padding: 10px 5px; }
  .currencyRiskRowContainer { width: auto; }
  .currencyRiskContainer { padding: 0; justify-content: center; }
  .riskDataContainer { flex-basis: 100%; padding: 0 10px; }
  .riskDataContainer .riskModeContainer label { font-size: inherit; }
}
