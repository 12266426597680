* { margin: 0; padding: 0; }
html { height: 100%; width: 100%; }
body { height: 100%; font: 16px/18px Roboto, Noto, sans-serif; color: #b4c2ce; background: #141c23; display: flex; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.button { display: inline-flex; margin-bottom: 0; text-align: center; justify-content: center; align-items: center; white-space: nowrap; overflow: hidden; max-width: 100%; min-width: 32px; text-overflow: ellipsis; vertical-align: middle; touch-action: manipulation; cursor: pointer; background-image: none; border: 0; font-family: Open Sans, helvetica, arial, sans-serif; border-radius: 4px; box-sizing: border-box; letter-spacing: 0.24px; font-size: 12px; line-height: 16px; font-weight: 600; padding: 8px; position: relative; transition-duration: 0.25s, 0.25s, 0.25s; transition-property: color, box-shadow, background-color; transition-timing-function: ease-in-out, ease-in-out, ease-in-out; flex-shrink: 0; color: #6b7b8b; background-color: #fff; box-shadow: inset 0 0 0 1px #6b7b8b; }
.button_primary { background-color: #173337; color: #00a59a; box-shadow: inset 0 0 0 1px #1f454a; }
.button_primary:hover { background-color: #173337; color: #00a59a; box-shadow: inset 0 0 0 1px #00a59a; }
.button_md { font-size: 16px; line-height: 20px; font-weight: 600; padding: 12px; }
.button_sm { font-size: 12px; line-height: 16px; font-weight: 600; padding: 8px; min-width: 28px; }
.button_block { width: 100%; max-width: 100%; white-space: normal; }

.textInput { color: #b4c2ce; border: 1px solid #2f4050; background: #141c23; border-radius: 4px; box-sizing: border-box; }
.textInput_md { font-size: 16px; line-height: 20px; font-weight: 600; padding: 12px; }
.textInput_sm { font-size: 12px; line-height: 16px; font-weight: 600; padding: 8px; min-width: 28px; }
