#navigator { position: fixed; width: 100%; background: #182129; border-bottom: 1px solid #2f4050; z-index: 2; }
#navigator #mobileTriggerMenuToggler { display: none; }
#navigator ul { display: flex; justify-content: center; list-style: none; }
#navigator ul li { display: flex; margin: 0 0; }
#navigator ul li a { text-decoration: none; color: inherit; font-weight: 400; font-size: 18px; padding: 10px 20px; transition: color 0.25s linear, background-color 0.25s linear; }
#navigator ul li a:hover { color: currentColor; background-color: #222e3a; }

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px) {
  #navigator { display: block; -webkit-user-select: none; user-select: none; height: 40px; }
  #navigator #mobileTriggerMenuToggler { display: block; width: 33px; height: 22px; position: absolute; top: 8px; left: 5px; cursor: pointer; opacity: 0; /* hide this */ z-index: 3; /* and place it over the hamburger */ -webkit-touch-callout: none; }
  #navigator #mobileBurgerMenuToggler { position: absolute; top: 8px; left: 5px; }
  #navigator #mobileBurgerMenuToggler span { display: block; width: 33px; height: 4px; margin-bottom: 5px; position: relative; background: #cdcdcd; border-radius: 3px; z-index: 2; transform-origin: 4px 0; transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }
  #navigator #mobileBurgerMenuToggler span:first-child { transform-origin: 0% 0%; }
  #navigator #mobileBurgerMenuToggler span:nth-last-child(1) { transform-origin: 0% 100%; margin-bottom: 0; }
  #navigator #mobileTriggerMenuToggler:checked ~ #mobileBurgerMenuToggler span { opacity: 1; transform: rotate(38deg) translate(2px, -2px); background: #cdcdcd; }
  #navigator #mobileTriggerMenuToggler:checked ~ #mobileBurgerMenuToggler span:nth-last-child(2) { opacity: 0; transform: rotate(0deg) scale(0.2, 0.2); }
  #navigator #mobileTriggerMenuToggler:checked ~ #mobileBurgerMenuToggler span:nth-last-child(1) { transform: rotate(-38deg) translate(1px, 2px); }
  #navigator ul { display: block; position: fixed; flex-direction: column; width: 200px; min-height: 100%; padding-top: 40px; background: #2f4050; transform-origin: 0% 0%; transform: translate(-100%, 0); transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1); }
  #navigator ul li { font-size: 22px; }
  #navigator ul li a { flex-shrink: 0; flex-grow: 1; }
  #navigator #mobileTriggerMenuToggler:checked ~ ul { transform: none; }
}
